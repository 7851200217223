<template>
	<el-dialog class="dialog" title="新增" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="180px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="广园型号：" prop="gyStandard"><el-input v-model="formData.gyStandard" :disabled="dialogObj.type == 2"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="汉威型号：" prop="hwStandard"><el-input v-model="formData.hwStandard"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="名称：" prop="name"><el-input v-model="formData.name"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="齿数：" prop="teeth"><el-input v-model.number ="formData.teeth" type="number"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注：" prop="remarks"><el-input v-model="formData.remarks"></el-input></el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
var regu = /^[0-9]+\.?[0-9]*$/;
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			formData: {},
			ents: [],
			rules: {
				name: [
					{
						required: true,
						message: '请填写名称',
						trigger: 'change'
					}
				],
				gyStandard: [
					{
						required: true,
						message: '请填写广园型号',
						trigger: 'change'
					}
				],
				hwStandard: [
					{
						required: true,
						message: '请填写汉威型号',
						trigger: 'change'
					}
				],
				teeth: [
					{
						required: true,
						message: '请填写齿数',
						trigger: 'change'
					}
				]
			}
		};
	},
	props: {},
	watch: {},
	methods: {
		//打开弹窗
		open() {
			this.formData = {};
			if (this.dialogObj.type == 2) {
				this.get();
			}
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
		},
		get() {
			this.$get('/backend-api/comp/type/gear/get', {
				id: this.dialogObj.row.id
			}).then(res => {
				if (res.code == 1000) {
					this.formData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//提交表单
		submit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.loading = true;
					console.log(this.formData.pitchDiameter);
					this.$postData('/backend-api/comp/type/gear/save', this.formData).then(res => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					});
				} else {
					return false;
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep .cascader {
		display: block;
	}
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
::v-deep input[type='number'] {
	-moz-appearance: textfield;
}
</style>
